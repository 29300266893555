// In src/pages/Page.js
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { RichText, linkResolver } from 'prismic-reactjs';

const ContactForm = (props) => {
    // console.log(props.data)
    const [success, setSuccess] = useState(null);
    const UPLOAD_URL = props.data.primary.form_action;
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        axios
          .post(UPLOAD_URL, data)
          .then((res) => {
            setSuccess(true)
          })
          .catch((err) => setSuccess(false));
      };
    return (
        <div className="contact-form" id={props.data.primary.page_anchor}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-lg-6">
                     
                            <RichText render={props.data.primary.heading} linkResolver={linkResolver} />
                            <RichText render={props.data.primary.intro_text} linkResolver={linkResolver} />
                       
                    </div>
                    <div className="col-lg-6">
                    {success === false || success == null ?
                        <div className="form-area">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {props.data.items.map((field, index) => {  
                                    if(field.field_type === "textarea") {
                                        if(field.field_required ===true) {
                                            return (
                                                <label key={index} >
                                                    <textarea {...register(field.field_name, { required: true })} className="required"  name={field.field_name} rows="8"></textarea>
                                                </label>
                                            )
                                        }
                                        else {
                                            return (
                                                <label key={index} >
                                                    <textarea {...register(field.field_name)} name={field.field_name} rows={6} placeholder="message"></textarea> 
                                                </label>
                                            )
                                        }
                                    }    
                                    else if (field.field_type !== "submit" ) {
                                        if(field.field_required ===true) {
                                            return (
                                            <label key={index} >
                                                <input placeholder={field.field_value} type={field.field_type} name={field.field_name} className="required" {...register(field.field_name, { required: true })}/>
                                            </label>
                                            
                                            )
                                         }
                                         else {
                                             return ( 
                                                <label key={index} >
                                                    <input placeholder={field.field_name} type={field.field_type} name={field.field_name} {...register(field.field_name)}/><br/>
                                                </label>
                                             )
                                            }
                                        }
                                    else {
                                        return null;
                                    }
                                    
                                    })}
                                    <input type="submit" value="Submit" key={100} />
                            </form>
                        </div>
                        :   
                            <div className="success">
                                <h3>Thank you for contacting us</h3>
                            </div> 
                        }    
                    </div>
                </div>
            </div>
            <div className="blue">
                <div className="green">

                </div>
                <div className="darkblue">
                    
                </div>
            </div>
        </div>
    )
}
export default ContactForm