import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const ThreeColumnFeature = (props) => {
        // console.log(props)
        return (
            <div className={"two-columns "+props.data.primary.background}>
                <div className="wrapper">
                    <h2>{props.data.primary.feature_title}</h2>
                    <div className="row">
                        <div className="col-lg-4">
                            <RichText render={props.data.primary.title} linkResolver={linkResolver} /> 
                        </div>
                        <div className="col-lg-8">
                            <RichText render={props.data.primary.intro_text} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default ThreeColumnFeature;