import React, {useState} from 'react';

const Header = (props) => {
        // console.log(props)

        const [menu, setMenu] = useState(false)

        return (
            <div className={"header " +props.stickyState}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-5 logo">
                            <a href="/"><img src={props.data.primary.logo.url} alt={props.data.primary.logo.alt} /></a>
                        </div> 
                        <div className="col-7 desktop text-right">
                                <ul>
                                {props.data.items.map((link, index)=>{
                                    return (
                                        <li key={index}><a href={'/'+link.link?.uid}>{link.link_name}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="col-5 mobile text-right">
                                <ul>
                                    <li className="menu-bars"  onClick={()=>setMenu(true)}>
                                        <img src="/images/menu.png"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                {menu === true ?
                <div className="product-menu mobile">
                    <div className="close-menu" onClick={()=>setMenu(false)}>
                        <img src="/images/close.png"/>
                    </div>
                    <ul>
                        {props.data.items.map((link, index)=>{
                            return (
                                    <li key={index}><a href={'/'+link.link?.uid}>{link.link_name}</a></li>
                                )
                        })}
                    </ul>
                    </div>
                :<></>}
                </div>
            );
}

export default Header;